import { default as React, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { TABLE_LAYOUT } from '../../App/AppSettings';
import { AccordionContainer, Plaintext } from '../../Shared/Forms';
import { BigPreviewTable, SmallPreviewTable } from '../FormComponents/TableComponent';
import LicenceOverview from './LicenceOverview.js';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { getLangKey } from '../../Content/DisplayComponents/DisplayUtils';
import utils from 'formiojs/utils';
import { fetchRequest, getParams } from '../../Shared/Actions';

const LABEL_SIZE = 6;
const INPUT_SIZE = 6;

const IS_UPPER = false;
let formIOFieldsList = [];
let formIOSections = [];
let formIOFields = [];

class FormApplicationDetails extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            //Check whether webpage is loaded
            isLoaded: false
        };
    }

    toggleModal = (modalName, ID) => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            modalID: ID
        });
    };

    getFormIODataAsynce = async (formIOUrl) => {
        const formIOData = await fetchRequest(formIOUrl, getParams(), false);
        const flattened = utils.flattenComponents(formIOData.body.components, true);
        const flattenedArray = Object.entries(flattened).filter(form => !form[0].includes("column"));
        formIOFieldsList = flattenedArray.map(([key, value]) => ({
            label: value.title ?? value.label,
            value: key,
            type: value.type
        }));
        this.mapFormIOFieldsToSections(formIOFieldsList);
    }

    mapFormIOFieldsToSections = (flattenedArray) => {
        flattenedArray.forEach(element => {
            if (element.type === "panel" && !formIOSections.find(section => section.value === element.value)) {
                formIOSections.push(element);
            } else if (element.type !== "panel" && !formIOFields.find(field => field[0].value === element.value)) {
                formIOFields.push(([element, formIOSections.length - 1]));
            }
        });
    }

    render() {
        const { t } = this.props;
        const { values, toggleSection, sectionNames, applicationType, formDefinition } = this.props;
        const { LicenceDetails } = values;
        
        let LicenceData = '';

        if (LicenceDetails) {
            LicenceData = JSON.parse(LicenceDetails.LicenceData);
        }

        const applyValues = Object.assign({}, values.FormData.GeneralInformation, values.FormData.LicenceInformation);
        this.getFormIODataAsynce(formDefinition.FormIOUrl);
        
        return (
            <>
                {applicationType === 'Cancel' && LicenceData &&
                    <AccordionContainer
                        title={sectionNames.CANCELLATION_INFORMATION.title}
                        active={sectionNames.CANCELLATION_INFORMATION.status}
                        onClick={toggleSection}
                        isIndividual={true}
                        sections={sectionNames}
                    >
                        <div className="card-body">
                            <div className="form-body form-preview">
                                <div className="multi-row-form">

                                    <Plaintext
                                        label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_FIELD_REMARKS)}
                                        name={'Remarks'}
                                        value={applyValues.Remarks}
                                        isUpper={IS_UPPER}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_FIELD_EFFECTIVEENDDATE)}
                                        name={'EndDate'}
                                        value={applyValues.EndDate}
                                        isUpper={IS_UPPER}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />

                                </div>
                            </div>
                        </div>
                    </AccordionContainer>
                }
                {formDefinition.Sections !== undefined && formDefinition.Sections !== null && formDefinition.Sections.length !== 0 &&
                    <AccordionContainer
                        title={sectionNames.SERVICE_APP_DETAILS.title}
                        active={sectionNames.SERVICE_APP_DETAILS.status}
                        onClick={toggleSection}
                        isIndividual={true}
                        sections={sectionNames}
                    >
                        <div className="card-body">
                            <div className="form-body form-preview">
                                {
                                    formDefinition.Sections.map((section, index) => {
                                        return (
                                            <div key={index} className="multi-row-form">
                                                <h3>{t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_SECTION_KEY, section.Name))}</h3>
                                                {
                                                    section.Fields && section.Fields.map((field, findex) => {
                                                        if (field.DataSource === 'Remote') {
                                                            return (
                                                                <Plaintext
                                                                    key={findex}
                                                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                                                                    name={field.Name}
                                                                    value={t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, field.DataParameters.Category, applyValues[field.Name]))}
                                                                    isUpper={IS_UPPER}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                            );
                                                        } else {
                                                            return (
                                                                <Plaintext
                                                                    key={findex}
                                                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                                                                    name={field.Name}
                                                                    value={applyValues[field.Name]}
                                                                    isUpper={IS_UPPER}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                            );
                                                        }
                                                    })
                                                }
                                                {
                                                    section.Table && (section.Table.Fields.length <= TABLE_LAYOUT.COLUMNS) &&
                                                    <SmallPreviewTable
                                                        section={section}
                                                        values={values}
                                                        inputSize={INPUT_SIZE}
                                                    />
                                                }
                                                {section.Table && (section.Table.Fields.length > TABLE_LAYOUT.COLUMNS) &&
                                                    <BigPreviewTable
                                                        section={section}
                                                        values={values}
                                                        labelSize={LABEL_SIZE}
                                                        inputSize={INPUT_SIZE}
                                                    />
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>

                    </AccordionContainer>
                }
                {formDefinition.FormIOUrl !== null && formDefinition.FormIOUrl !== undefined && 
                    <AccordionContainer
                        title={sectionNames.SERVICE_APP_DETAILS.title}
                        active={sectionNames.SERVICE_APP_DETAILS.status}
                        onClick={toggleSection}
                        isIndividual={true}
                        sections={sectionNames}
                    >
                        <div className="card-body">
                            <div className="form-body form-preview">
                            {formIOSections.map((section, index) => {
                                return (
                                    <div key={index} className="multi-row-form">
                                        <h3>{section.label}</h3>
                                        {formIOFields.filter(field => field[1] === index).map((field, findex) => {
                                            return (
                                            <Plaintext
                                                key={findex}
                                                label={field[0].label}
                                                name={field[0].label}
                                                value={applyValues[field[0].value]}
                                                isUpper={IS_UPPER}
                                                labelSize={LABEL_SIZE}
                                                inputSize={INPUT_SIZE}
                                            />);
                                        })}
                                    </div>
                                );
                            })}
                            </div>
                        </div>
                    </AccordionContainer>
                }
                {
                    applicationType !== 'Apply' && LicenceData &&
                    <LicenceOverview
                        values={values}
                        toggleSection={toggleSection}
                        sectionNames={sectionNames}
                    />
                }
            </>
        );
    }
}
export default withTranslation()(FormApplicationDetails);
